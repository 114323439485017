@import "~bootstrap/scss/bootstrap";

.posNavDropdown {
    &.nav-pills {
        --bs-nav-pills-border-radius: 0.375rem;
        --bs-nav-pills-link-active-color: #fff;
        --bs-nav-pills-link-active-bg: #6c757d;
    }

    &.nav {
        --bs-nav-link-padding-x: 1rem;
        --bs-nav-link-padding-y: 0.5rem;
        // --bs-nav-link-font-weight: ;
        --bs-nav-link-color: #6c757d;
        --bs-nav-link-hover-color: black;
        --bs-nav-link-disabled-color: red;
    }

    .nav-link {
        border: 1px solid var(--bs-nav-link-color);
    }

    .nav-item {
        flex-grow: 1;
        margin-right: 5px;
        text-align: center;

        &:last-child {
            margin-right: 0;
        }

        .dropdown-menu {
            width: 100%;
            padding: 0;
            text-align: center;
            --bs-dropdown-link-active-bg: #198754;
        }
    }
}

.orderDetails {
    &.pagination {
        --bs-pagination-color: #6c757d;
        --bs-pagination-bg: #fff;
        --bs-pagination-border-width: 1px;
        --bs-pagination-border-color: #6c757d;
        --bs-pagination-border-radius: 0.375rem;
        // --bs-pagination-hover-color: var(--bs-link-hover-color);
        // --bs-pagination-hover-bg: #e9ecef;
        --bs-pagination-hover-border-color: #6c757d;
        // --bs-pagination-focus-color: var(--bs-link-hover-color);
        // --bs-pagination-focus-bg: #e9ecef;
        --bs-pagination-focus-box-shadow: 0 0 0 0.25rem #6c757d;
        --bs-pagination-active-color: #fff;
        --bs-pagination-active-bg: #6c757d;
        --bs-pagination-active-border-color: #6c757d;
    }
}
td{
    font-size: 14px;
}
th{
    font-size: 14px;
}